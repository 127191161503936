<template>
  <div class="banner">
    <swiper :options="swiperOption" ref="mySwiper" v-show="show">
      <swiper-slide v-for="(item, index) in swiperSlides" :key="index">
        <img :src="item" @click="goDetail(item)" />
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-if="swiperSlides.length > 1"
        slot="pagination"
      ></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "Banner",
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    mySwiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    goDetail(url) {
      if (url.indexOf("banner-epidemic") > -1) {
        window.open("http://topic.dataelements.cn/2019-nCoV");
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 50);
    // this.swiperSlides.push();
    this.mySwiper.on("slideChange", function () {
      document
        .getElementsByClassName("swiper-pagination-bullet")
        .forEach((ele, index) => {
          if (this.activeIndex - 1 === 4 && index === 0) {
            ele.classList.add("swiper-pagination-bullet-active");
          } else {
            if (this.activeIndex - 1 === index) {
              ele.classList.add("swiper-pagination-bullet-active");
            } else {
              ele.classList.remove("swiper-pagination-bullet-active");
            }
          }
        });
    });
  },
  data() {
    return {
      show: false,
      swiperSlides: [
        require("@/assets/images/banner1.png"),
        require("@/assets/images/banner2.png"),
        require("@/assets/images/banner3.png"),
        require("@/assets/images/banner-epidemic.jpg"),
      ],
      swiperOption: {
        loop: true,
        initialSlide: 0,
        notNextTick: true,
        // autoplay: {
        // 	delay: 3000,
        // 	stopOnLastSlide: false,
        // 	disableOnInteraction: true
        // },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>

<style scoped lang='less'>

.banner {
  .swiper-container {
    height: auto;
    width: 100%;

    .swiper-slide {
      img {
        height: auto;
        width: 100%;
      }

      width: 100% !important;
    }

    /deep/ .bullet-active {
      background: rgba(255, 255, 255, 0.8);
    }

    /deep/ .swiper-pagination-bullet {
      width: 0.875rem;
      height: 0.875rem;
      border: 1px rgba(255, 255, 255, 0.8) solid;
    }
  }
}
</style>