<template>
    <div class="product-line">
        <common-title zhTitle="两大产品线" enTitle="Two product lines"></common-title>
        <img src="@/assets/images/product-line.png">
        <div class="product-line-footer">
            数智工场作为区域产业发展服务提供商，基于自身业务能力，以大数据应用为切入点，打造数智园区，助力园区全面升级
        </div>
    </div>
</template>

<script>
	import CommonTitle from '@/components/Common/CommonTitle';

	export default {
		name: "ProductLine",
		components: {
			CommonTitle
		}
	}
</script>

<style scoped lang='less'>
.product-line {
    padding: 1.125rem .9375rem;
    background: url("../../../assets/images/product-bg.png") no-repeat;
    background-size: cover;
    img {
        width: 100%;
    }
    /deep/ .common-title {
        margin-bottom: 1.4375rem;
        div {
            color: #fff;
        }
        .line {
            background: #fff;
        }
    }
    .product-line-footer {
        font-size: .6875rem;
        color: #fff;
        text-align: left;
        line-height: 1.03125rem;
        margin-top: 1.28125rem;
    }
}
</style>