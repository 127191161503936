<template>
    <Footer>
        <div class="content">
            <div class="qr-code">
                <div class="item">
                    <img src="@/assets/images/the-public.png"/>
                    <div>公众号</div>
                </div>
                <div class="item">
                    <img src="@/assets/images/recruitment.png"/>
                    <div>招聘专员</div>
                </div>
            </div>
            <div class="info">
                <div class="phone">
                    <img src="@/assets/images/phone.png"/>工作电话：010-53354816
                </div>
                <div class="email">
                    <img src="@/assets/images/mail.png"/>工作邮箱：business@diworks.cn
                </div>
            </div>
        </div>
        <div class="footer-info">
            Copyright&nbsp;©&nbsp;数智工场科技产业有限公司&nbsp;&nbsp;<a href="https://beian.miit.gov.cn">京ICP备19032936号-2</a>
        </div>
    </Footer>
</template>

<script>
	export default {
		name: "CustomizeFooter"
	}
</script>

<style scoped lang='less'>
.ivu-layout-footer {
    background: #000;
    padding: 0;
    .content {
        .info {
            padding-left: 23%;
            font-size: .6875rem;
            color: #fff;
            margin-bottom: 1.03125rem;
            .phone {
                margin-right: 3.75rem;
                margin-bottom: .53125rem;
            }
            div {
                display: flex;
                align-items: center;
            }
            img {
                margin-right: .3125rem;
                width: .915625rem;
                height: .915625rem;
            }
        }
        .qr-code {
            display: flex;
            margin-top: 1.25rem;
            justify-content: center;
            margin-bottom: 1.34375rem;
            .item {
                img {
                    width: 4.125rem;
                    height:4.125rem;
                    margin-bottom: .8125rem;
                }
                div {
                    font-size: .6875rem;
                    line-height: .6875rem;
                    color: #fff;
                }
            }
            .item:first-child {
                margin-right: 2.5rem;
            }
        }
    }
    .footer-info {
        border-top: 1px solid #666;
        font-size: .6875rem;
        line-height: 1.0625rem;
        padding: .9375rem 1.0625rem;
        color: #fff;
    }
}
</style>