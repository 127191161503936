<template>
    <div class='brochure-page'
         @mousedown='goTouchstart'
         @mousemove='goTouchmove'
         @mouseup='goTouchend'
         @touchstart='goTouchstart'
         @touchmove='goTouchmove'
         @touchend='goTouchend'>
        <img src='@/assets/images/brochure/first.jpg'/>
        <img src='@/assets/images/brochure/second.jpg'/>
        <img src='@/assets/images/brochure/third.jpg'/>
        <img src='@/assets/images/brochure/fourth.jpg'/>
        <img src='@/assets/images/brochure/fifth.jpg'/>
    </div>
</template>

<script>
	export default {
		name: 'Brochure',
		data() {
			return {
				timeOutEvent: 0,
				src: ''
			}
		},
		mounted() {
		},
		methods: {
			goTouchstart(e) {
				if (e.target.nodeName.toLowerCase() === 'img') {
					this.src = e.target.src
				}
				clearTimeout(this.timeOutEvent);
				this.timeOutEvent = 0;
				this.timeOutEvent = setTimeout(() => {
					this.download()
				}, 600);
			},
			goTouchend() {
				clearTimeout(this.timeOutEvent);
			},
			goTouchmove() {
				clearTimeout(this.timeOutEvent);
				this.timeOutEvent = 0;
			},
			download() {
				let type = 'jpg';
				let img = new Image;
				let d = document.createElement('canvas');
				let ctx = d.getContext('2d');
				img.crossOrigin = 'Anonymous';
				img.onload = function () {
					d.width = img.width;
					d.height = img.height;
					ctx.drawImage(img, 0, 0);
					let imgData = d.toDataURL(type);
					let fixtype = function (type) {
						type = type.toLocaleLowerCase().replace(/jpg/i, 'jpeg');
						let r = type.match(/png|jpeg|bmp|gif/)[0];
						return 'image/' + r;
					};
					imgData = imgData.replace(fixtype(type), 'image/octet-stream');
					let saveFile = function (data, filename) {
						let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
						save_link.href = data;
						save_link.download = filename;
						let event = document.createEvent('MouseEvents');
						event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						save_link.dispatchEvent(event);
					};
					let filename = new Date().getTime() + '.' + type;
					saveFile(imgData, filename);
				};
				img.src = this.src;
			}
		}
	}
</script>

<style scoped lang='less'>
    .brochure-page {
        img {
            width: 100%;
        }
    }
</style>