import axios from '@/libs/api.request'

export const getArticleList = () => {
  return axios.request({
    url: 'getArticleList',
    method: 'get',
  })
}


export const getShareInfo = (url) => {
  return axios.request({
    url,
    method: 'get'
  })
}