<template>
    <Header :class="{'white': scrollTop < 110, 'yellow': scrollTop >= 110}">
        <img :src="scrollTop < 110 ? require('@/assets/images/logo-white.png') : require('@/assets/images/logo-yellow.png')">
        <div class="menu-list" v-if="screenWidth >= 1200">
            <label :class="{'active': type === 'four-services'}" @click="changeType('four-services')">四大服务</label>
            <label :class="{'active': type === 'product-line'}"
                   @click="changeType('product-line')">两大产品线</label>
            <label :class="{'active': type === 'company-news'}" @click="changeType('company-news')">公司动态</label>
            <label :class="{'active': type === 'job-dynamics'}" @click="changeType('job-dynamics')">职位动态</label>
            <label :class="{'active': type === 'strategic-partners'}"
                   @click="changeType('strategic-partners')">战略伙伴与客户</label>
            <label :class="{'active': type === 'about-us'}" @click="changeType('about-us')">关于我们</label>
        </div>
        <Dropdown trigger="click" v-if="screenWidth < 1100" @on-click="changeType">
            <Icon type="md-menu"/>
            <DropdownMenu slot="list">
                <DropdownItem name="four-services">四大服务</DropdownItem>
                <DropdownItem name="product-line">两大产品线</DropdownItem>
                <DropdownItem name="company-news">公司动态</DropdownItem>
                <DropdownItem name="job-dynamics">职位动态</DropdownItem>
                <DropdownItem name="strategic-partners">战略伙伴与客户</DropdownItem>
                <DropdownItem name="about-us">关于我们</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </Header>
</template>

<script>
	import Utils from '../../utils/utils';

	export default {
		name: "CustomizeHeader",
		data() {
			return {
				screenWidth: document.body.clientWidth,
				type: 'four-services',
				scrollTop: 0,
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true);
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					this.screenWidth = window.screenWidth
				})()
			}
		},
		methods: {
			aaa(a) {
				console.log(a)
			},
			handleScroll(e) {
				this.scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
				this.type = Utils.elementPosition();
			},
			changeType(type) {
				if (type !== this.type) {
					window.scrollTo({
						top: document.getElementsByClassName(type)[0].offsetTop - 100,
						behavior: 'smooth'
					});
					this.type = type
				}
			}
		}
	}
</script>

<style scoped lang='less'>
    .ivu-layout-header {
        background: #000;
        display: flex;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 20;
        align-items: center;
        justify-content: space-between;
        height: 4.96875rem;
        padding: 0 30px;

        img {
            height: 1.5625rem;
            width: 7.8125rem;
        }

        .menu-list {
            label {
                color: rgba(255, 255, 255, .6);
                position: relative;
                cursor: pointer;
            }

            .active {
                color: #fff;
                font-weight: bold;
            }

            label:not(:first-child) {
                margin-left: 2rem;
            }

            .active:before {
                position: absolute;
                content: '';
                top: 100%;
                height: 2px;
                width: 100%;
                background: #fff;
            }
        }

        .ivu-dropdown {
            cursor: pointer;
            font-size: 2rem;
            color: #fff;
        }
    }

    .yellow {
        background: #fff;

        label {
            color: rgba(0, 0, 0, .6) !important;
        }

        .active {
            color: #000 !important;
        }

        .active:before {
            background: #000 !important;
        }

        .ivu-dropdown {
            color: #000;
        }
    }
</style>
