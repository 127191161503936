<template>
    <div class="common-title">
        <div class="zh-title">{{zhTitle}}</div>
        <div class="en-title">{{enTitle}}</div>
        <div class="line"></div>
    </div>
</template>

<script>
	export default {
		name: "CommonTitle",
		props: {
			zhTitle: {
				type: String,
				default: ''
			},
			enTitle: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style scoped lang='less'>
    .common-title {
        .zh-title {
            color: #333;
            font-size: .9375rem;
            font-weight: bold;
            line-height: .9375rem;
        }

        .en-title {
            font-size: .625rem;
            color: #1c2c5a;
            line-height: .625rem;
            opacity: .8;
            margin-top: .28125rem;
            margin-bottom: .4375rem;
        }

        .line {
            height: 2px;
            background: #1c2c5a;
            width: 3.625rem;
            margin-left: calc(~"50% - 28px");
        }
    }
</style>