<template>
    <div class="strategic-partners">
        <common-title zhTitle="战略伙伴与客户" enTitle="Strategic partners and customers"></common-title>
        <swiper :options="swiperOption">
            <swiper-slide>
                <Row>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/chief-data.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/CLP-investment.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/dragon-shield-data.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/fourth-flight.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/hongkun-cultural-brigade.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/xinchuang-technology.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/fanhua-group.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/rombang-ruiming.png">
                    </Col>
                </Row>
            </swiper-slide>
            <swiper-slide>
                <Row>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/industry-gang.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/binzhou-people-government.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/gztcj.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/xzzg.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/xzsd.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/qzrmzf.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/dygx.png">
                    </Col>
                    <Col span="12">
                        <img src="@/assets/images/mobile/partners/dyhk.png">
                    </Col>
                </Row>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
    </div>
</template>

<script>
	import CommonTitle from '@/components/Common/CommonTitle';
	import {swiper, swiperSlide} from 'vue-awesome-swiper'

	export default {
		name: "StrategicPartners",
		components: {
			CommonTitle,
			swiper,
			swiperSlide
		},
		data() {
			return {
				swiperOption: {
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				}
			}
		}
	}
</script>

<style scoped lang='less'>
    .strategic-partners {
        padding: 1.25rem .9375rem;
        background: url("../../../assets/images/strategic-partners-bg.png") no-repeat;
        background-size: cover;

        .ivu-row {
            .ivu-col {
                img {
                    width: 8.0625rem;
                    height: 2.375rem;
                }
            }
        }

        /deep/ .common-title {
            margin-bottom: 1.4375rem;

            div {
                color: #fff;
            }

            .line {
                background: #fff;
            }
        }
        .swiper-button-prev {
            height: 1.625rem;
            left: 0;
            z-index: 15;
        }
        .swiper-button-next {
            height: 1.625rem;
            right: 0;
        }
    }
</style>