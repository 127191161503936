<template>
    <div class="job-dynamics">
        <common-title zhTitle="职位动态" enTitle="Position dynamic"></common-title>
        <div class="content">
            <div class="title">尊敬的应聘者：</div>
            <div class="info">感谢您关注数智工场人才招聘，为保证招聘的及时性和有效性，数智工场招聘相关信息已在智联、拉勾、BOSS直聘、
                企业公众号等平台在线发布，请通过各平台进行职位查询和申请，同一职位在一个平台申请即可。谢谢！</div>
            <Row>
                <Col span="8">
                    <img src="@/assets/images/job/front-end.png">
                    <div>Web前端开发</div>
                </Col>
                <Col span="8">
                    <img src="@/assets/images/job/it-project-manager.png">
                    <div>IT项目经理</div>
                </Col>
                <Col span="8">
                    <img src="@/assets/images/job/industry-analyst.png">
                    <div>产业分析师</div>
                </Col>
                <Col span="8">
                    <img src="@/assets/images/job/industry-consultant.png">
                    <div>产业咨询师</div>
                </Col>
                <Col span="8">
                    <img src="@/assets/images/job/industry-consulting.png">
                    <div>产业咨询项目经理</div>
                </Col>
                <Col span="8">
                    <img src="@/assets/images/job/business-manager.png">
                    <div>商务经理</div>
                </Col>
            </Row>
        </div>
    </div>
</template>

<script>
	import CommonTitle from '@/components/Common/CommonTitle';

	export default {
		name: "JobDynamics",
        components: {
			CommonTitle
        }
	}
</script>

<style scoped lang='less'>
.job-dynamics {
    padding: 1.1875rem .9375rem;
    .content {
        text-align: left;
        .title {
            font-size: .75rem;
            color: #333;
            font-weight: bold;
            margin-top: .59375rem;
            margin-bottom: .875rem;
        }
        .info {
            text-indent: 2rem;
            font-size: .75rem;
            color: #333;
            line-height: 1.5rem;
        }
        .ivu-row {
            .ivu-col {
                text-align: center;
                img {
                    width: 3.75rem;
                    height: 3.75rem;
                    margin-top: .9375rem;
                    margin-bottom: .59375rem;
                }
                div {
                    font-size: .6875rem;
                    color: #333;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>