<template>
    <div class="home">
        <Layout v-if="isPCDevice" class="pc">
            <customize-header></customize-header>
            <Content>
                <banner></banner>
                <four-services></four-services>
                <product-line></product-line>
                <company-news></company-news>
                <job-dynamics></job-dynamics>
                <strategic-partners></strategic-partners>
                <about-us></about-us>
            </Content>
            <customize-footer></customize-footer>
        </Layout>
        <Layout v-if="!isPCDevice" class="mobile">
            <mobile-header></mobile-header>
            <Content>
                <mobile-banner></mobile-banner>
                <mobile-four-services></mobile-four-services>
                <mobile-product-line></mobile-product-line>
                <mobile-company-news></mobile-company-news>
                <mobile-job-dynamics></mobile-job-dynamics>
                <mobile-strategic-partners></mobile-strategic-partners>
                <mobile-about-us></mobile-about-us>
            </Content>
            <mobile-footer></mobile-footer>
        </Layout>
    </div>
</template>

<script>
	import CustomizeHeader from '@/components/Layout/CustomizeHeader';
	import MobileHeader from '@/components/Layout/MobileHeader';
	import CustomizeFooter from '@/components/Layout/CustomizeFooter';
	import MobileFooter from '@/components/Layout/MobileFooter';
	import Banner from '@/components/Home/Banner';
	import FourServices from '@/components/Home/FourServices';
	import MobileFourServices from '@/components/Home/Mobile/FourServices';
	import ProductLine from '@/components/Home/ProductLine';
	import MobileProductLine from '@/components/Home/Mobile/ProductLine';
	import JobDynamics from '@/components/Home/JobDynamics';
	import MobileJobDynamics from '@/components/Home/Mobile/JobDynamics';
	import StrategicPartners from '@/components/Home/StrategicPartners';
	import MobileStrategicPartners from '@/components/Home/Mobile/StrategicPartners';
	import AboutUs from '@/components/Home/AboutUs';
	import MobileAboutUs from '@/components/Home/Mobile/AboutUs';
	import MobileBanner from '@/components/Home/Mobile/Banner';
	import CompanyNews from '@/components/Home/CompanyNews';
	import MobileCompanyNews from '@/components/Home/Mobile/CompanyNews';

	export default {
		name: 'home',
		data() {
			return {
				isPCDevice: true
			}
		},
		components: {
			StrategicPartners,
			MobileStrategicPartners,
			CustomizeHeader,
			MobileHeader,
			CustomizeFooter,
			Banner,
			FourServices,
			MobileFourServices,
			ProductLine,
			MobileProductLine,
			JobDynamics,
			MobileJobDynamics,
			AboutUs,
			MobileAboutUs,
			CompanyNews,
			MobileCompanyNews,
			MobileBanner,
			MobileFooter
		},
		mounted() {
			this.isPCDevice = this.isPC()
		},
		methods: {
			isPC() {
				let userAgentInfo = navigator.userAgent;
				let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
				let flag = true;
				for (let i = 0; i < Agents.length; i++) {
					if (userAgentInfo.indexOf(Agents[i]) > 0) {
						flag = false;
						break;
					}
				}
				return flag;
			}
		}
	}
</script>
<style lang="less">
    .home {
        .pc {
            .ivu-layout-content {
                margin-top: 6.875rem;
            }
            /deep/ .common-title {
                .zh-title {
                    font-size: 26px;
                    line-height: 19px;
                }

                .en-title {
                    font-size: 16px;
                    line-height: 19px;
                    margin-top: 0.5625rem;
                    margin-bottom: 0.875rem;
                }

                .line {
                    height: 2px;
                    width: 3.625rem;
                    margin-left: calc(~"50% - 28px");
                }
            }
        }
        .mobile {
            .ivu-layout-content {
                margin-top: 4.96875rem;
            }
        }
    }
</style>
