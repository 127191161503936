<template>
    <div class="four-services">
        <common-title zhTitle="四大服务" enTitle="The four services"></common-title>
        <Row>
            <Col :xl="6" :lg="12" :xs="24">
                <img src="@/assets/images/product-information.jpg">
                <div class="title">产业咨询服务</div>
                <div class="item"><span class="subtitle">产业规划：</span>产业体系规划、产业链规划、产业场景规划等</div>
                <div class="item"><span class="subtitle">产业情报：</span>产业创新与创投情报、企业与项目动态情报、对标园区情报等</div>
                <div class="item"><span class="subtitle">招商策略：</span>招商模型与名单、企业选址意向调研报告、招商促进措施方案等</div>
                <div class="item"><span class="subtitle">拓展策略：</span>产业地产项目选址、投资立项咨询等</div>
            </Col>
            <Col :xl="6" :lg="12" :xs="24">
                <img src="@/assets/images/industrial-big-data.png">
                <div class="title">产业大数据服务</div>
                <div class="item"><span class="subtitle">Saas产品：</span>
                    以企业大数据为核心，实现企业全景画像、企业图谱、企业智能搜索与推荐，产业链自定义与标签管理，企业监控等核心功能，
                    为产业招商、经济监测提供基础服务支撑</div>
                <div class="item"><span class="subtitle">平台部署：</span>以产业大数据业务中台为核心，实现经济监测、产业情报、
                    产业招商、产业促进、产业用地管理等功能模块定制</div>
            </Col>
            <Col :xl="6" :lg="12" :xs="24">
                <img src="@/assets/images/industry-investment.png">
                <div class="title">产业招商服务</div>
                <div class="item"><span class="subtitle">企业对接服务：</span>招商活动组织对接、企业考察选址对接等</div>
                <div class="item"><span class="subtitle">企业落地服务：</span>企业尽调与签约、企业投资与手续办理等</div>
            </Col>
            <Col :xl="6" :lg="12" :xs="24">
                <img src="@/assets/images/park-operation.jpg">
                <div class="title">园区运营服务</div>
                <div class="item"><span class="subtitle">企业促进服务：</span>企融对接服务；人才培训服务；政策对接与优化服务</div>
                <div class="item"><span class="subtitle">载体运营服务：</span>产业创新中心运营、数据智能产业园运营等</div>
                <div class="item"><span class="subtitle">园区投资服务：</span>园区基础设施投资、产业基金投资等</div>
            </Col>
        </Row>
    </div>
</template>

<script>
	import CommonTitle from '@/components/Common/CommonTitle';

	export default {
		name: "FourServices",
		components: {
			CommonTitle
		}
	}
</script>

<style scoped lang='less'>
    .four-services {
        padding: 3.125rem 20.31% 0 20.31%;

        .ivu-row {
            margin-top: 2.5rem;

            .ivu-col {
                padding: 0 0.59375rem;

                .title {
                    font-size: 1.125rem;
                    color: #333;
                    line-height: 1.1875rem;
                    font-weight: bold;
                    margin-top: 1.125rem;
                    margin-bottom: 1.1875rem;
                }
                .item {
                    font-size: 0.875rem;
                    color: #333;
                    line-height: 1.3125rem;
                    margin-bottom: 0.8125rem;
                    text-align: left;
                    .subtitle {
                        font-weight: bold;
                    }
                }
                img {
                    width: 100%;
                }
            }

            /*.ivu-col:first-child {*/
            /*    padding-left: 0;*/
            /*}*/

            /*.ivu-col:last-child {*/
            /*    padding-right: 0;*/
            /*}*/
        }
    }
</style>
