<template>
  <div id="iframeBox" class="iframe-box">
    <div class="iframe">
      <h3>{{ data.title }}</h3>
      <div class="source">
        <div>来源：{{ data.source }}</div>
        <div>{{ data.time }}</div>
      </div>
      <div v-html="data.html"></div>
    </div>
    <div class="footer">
      <div class="app-img">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="app-name">产业加加APP</div>
      <button @click="downLoad" class="app-open">下载</button>
    </div>
  </div>
</template>

<script>
import { getShareInfo } from "@/api/data.js";
export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.initData();
    const title = document.querySelector("title");
    title.innerText = "产业加加";
  },
  methods: {
    initData() {
      getShareInfo("java/api/getShareInfo/" + this.$route.query.id).then(
        (res) => {
          if (res.status == 200) {
            this.data = { ...res.data.data };
          }
        }
      );
    },
    downLoad() {
      this.$router.push({
        path: "/download",
        query: {
          url: this.data.packageDownloadUrl,
        },
      });
    },
  },
};
</script>

<style>
html,
body,
.iframe-box,
#app {
  width: 100%;
  height: 100%;
}
#iframeBox .iframe {
  text-align: left;
}
.iframe-box {
  display: flex;
  flex-direction: column;
}
.iframe {
  flex: 1;
  padding: 0 1rem;
  overflow: scroll;
}
.iframe h3{
  margin: 0.9rem 0 0.1rem;
  text-align: center;
}
.iframe table {
  width: 100%;
}
.iframe img {
  width: 100%;
}
.iframe .source{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #a0a0a0;
}

.footer {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  box-shadow: -1px 1px 3px 3px #eee;
  z-index: 10;
}

.footer .app-name {
  flex: 1;
  margin-left: 0.3rem;
}

.footer .app-img {
  width: 4rem;
  height: 4rem;
}

.footer .app-img img {
  width: 100%;
}

.footer .app-name {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
}

.footer .app-open {
  width: 4rem;
  height: 1.8rem;
  line-height: 1.8rem;
  font-size: 0.9rem;
  text-decoration: none;
  /* text-align: center; */
  background-color: #00a0e9;
  color: #fff;
  border: 1px solid transparent;
}
</style>