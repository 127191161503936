<template>
  <div class="down">
    <img
      src="@/assets/images/wx_download_tip.png"
      alt=""
      class="tip"
      v-if="showTip"
    />
    <div class="download-button" @click="down">立即下载</div>
  </div>
</template>

<script>
import { getShareInfo } from "@/api/data.js";
export default {
  data() {
    return {
      showTip: false,
    };
  },
  mounted() {
    const title = document.querySelector("title");
    title.innerText = "产业加加";
  },
  methods: {
    //判断IOS
    isIos(ua) {
      return /iphone|ipod|ipad/i.test(ua);
    },
    //判断安卓
    isAndroid(ua) {
      return /android/i.test(ua);
    },
    isWeiXin(ua) {
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    down() {
      const ua = navigator.userAgent.toLowerCase();
      console.log(ua);
      if (this.isWeiXin(ua)) {
        this.showTip = true;
      } else {
        getShareInfo("java/api/findAppInfo").then((res) => {
          location.href = res.data.data.apkDownloadUrl;
        });
      }
    },
  },
};
</script>

<style scoped>
.down {
  padding: 5rem 3rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/download.png");
  background-size: 100% 100%;
  position: relative;
}
.down .download-button {
  display: inline-block;
  width: 80%;
  height: 3rem;
  font-size: 1.4rem;
  text-decoration: none;
  background: transparent;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #000;
  line-height: 3rem;
}
.tip {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>