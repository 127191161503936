<template>
    <div class="company-news">
        <common-title zhTitle="公司动态" enTitle="The company dynamic"></common-title>
        <div class="right-index">
            <label :class="{'active': activeIndex === index}" @click="changeIndex(index)"
                   v-for="(_, index) in articleList" :key="index"></label>
        </div>
        <swiper :options="swiperOption" class="article-list" ref="mySwiper">
            <swiper-slide class="item" v-for="(item, index) in articleList" :key="index">
                <div class="title">{{item.title}}</div>
                <div class="content">
                    <div class="content-item" v-for="(content, i) in item.content" :key="i">
                        {{content}}
                    </div>
                </div>
                <Row>
                    <Col span="24" v-for="(imgContent, i) in item.img" :key="i">
                        <img :src="imgContent.url">
                        <div class="img-info">{{imgContent.info}}</div>
                    </Col>
                </Row>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
	import CommonTitle from '@/components/Common/CommonTitle';
	import {getArticleList} from '@/api/data'
	import {swiper, swiperSlide} from 'vue-awesome-swiper'
	import news from '@/assets/json/news.json'

	export default {
		name: "CompanyNews",
		components: {
			CommonTitle,
			swiper,
			swiperSlide
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper
			}
		},
		data() {
			return {
				swiperOption: {
					pagination: {
						el: '.swiper-pagination'
					}
				},
				activeIndex: 0,
				articleList: news
			}
		},
		created() {
			this.articleList = news;
			this.articleList.forEach(item => {
				item.img.forEach(img => {
                    if (img.url.indexOf('png') === -1) {
                        img.url = require('@/assets/images/CompanyNews/' + img.url + '.png')
                    }
				})
			})
		},
		mounted() {
			this.swiper.allowTouchMove = false
			// this.getData()
		},
		methods: {
			getData() {
				getArticleList().then(res => {
					if (res.status === 200) {
						this.articleList = res.data.result.content
					}
				})
			},
			changeIndex(index) {
				if (index !== this.activeIndex) {
					this.activeIndex = index;
					this.swiper.slideTo(index, 1000, false)
				}
			}
		}
	}
</script>

<style scoped lang='less'>
    .company-news {
        padding: 1.125rem .9375rem;
        position: relative;

        .swiper-slide-next {
            left: 1.9375rem;
        }
        .swiper-slide-prev {
            right: 1.9375rem;
        }

        .right-index {
            label {
                display: inline-block;
                background: #1c2c5a;
                margin-top: 0.625rem;
                cursor: pointer;
                width: .75rem;
                height: .25rem;
                opacity: .15;
            }
            label {
                margin-left: .3125rem;
            }
            .active {
                opacity: 1;
                width: 1.5rem;
            }
        }

        .article-list {
            .content {
                font-size: .75rem;
                color: #333;
                margin-bottom: .6875rem;
                text-align: left;

                &-item {
                    text-indent: 2rem;
                }
            }

            .item {
                .ivu-col {
                    img {
                        width: 100%;
                        height: auto;
                        margin-bottom: .5625rem;
                    }

                    .img-info {
                        color: #333;
                        font-size: .6875rem;
                        margin-bottom: .9375rem;
                    }
                }

                .title {
                    color: #333;
                    font-size: .875rem;
                    font-weight: bold;
                    margin-bottom: 1.09375rem;
                    margin-top: .9375rem;
                }
            }
        }
    }
</style>