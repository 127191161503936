import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import App from '../views/app.vue'
import Brochure from '../brochure/Brochure'
import H5Share from '../views/h5/index'
import downLoad from '../views/h5/download'
Vue.use(VueRouter);

const routes = [
  {
    path: '/brochure',
    name: 'brochure',
    component: Brochure
  },
  {
    path: '/app',
    name: 'app',
    component: App
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/h5share',
    name: 'h5share',
    component: H5Share
  }, {
    path: '/download',
    name: 'download',
    component: downLoad
  },
  {
    path: '*',
    name: 'home',
    component: Home
  }
];

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

export default router
