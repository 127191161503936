<template>
    <div class="about-us">
        <common-title zhTitle="关于我们" enTitle="About us"></common-title>
        <div class="content">
            聚焦 <span>产业大数据与园区数智化运营</span>
            ，数智工场致力于搭建以人工智能、大数据、云计算、物联网与数字安全（ABCIS）为核心的区域发展赋能平台，提供
            <span>产业咨询、精准招商、园区运营</span>
            等三大服务，为区域发展注入数智经济新动能，助力区域产业做大做优做强。
        </div>
        <img src="@/assets/images/about-us.png">
    </div>
</template>

<script>
	import CommonTitle from '@/components/Common/CommonTitle';

	export default {
		name: "AboutUs",
		components: {
			CommonTitle
		}
	}
</script>

<style scoped lang='less'>
    .about-us {
        padding: 3.4375rem 20.31% 5.625rem 20.31%;
        .content {
            font-size: 1rem;
            color: #333;
            line-height: 1.5rem;
            text-align: left;
            text-indent: 2rem;
            margin-top: 1.9375rem;
            margin-bottom: 3.5625rem;
            span {
                font-weight: bold;
            }
        }
        img {
            width: 100%;
        }
    }
</style>