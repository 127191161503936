<template>
    <div>
        产业加加是为产业人提供在线招商及项目投资选址所需的随身工具，助力找到产业（industry）和投资（investment）方向
        <div>主要包括首页榜单模块,雷达模块,我的模块</div>
    </div>
</template>

<script>
	export default {
		name: "app"
	}
</script>

<style scoped lang='less'>
    div {
        padding-top: 20%;
    }
</style>
